// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--kszNP";
export var btnBlock = "banner-module--btnBlock--EWfuk";
export var container = "banner-module--container--BYmkt";
export var hideMobBlock = "banner-module--hideMobBlock--LZG93";
export var img = "banner-module--img--idDGi";
export var mobBtn = "banner-module--mobBtn--AzXUH";
export var red = "banner-module--red--9AYhc";
export var secondContainer = "banner-module--secondContainer--cmcy4";
export var section = "banner-module--section--MBafh";
export var text = "banner-module--text--k7rFJ";